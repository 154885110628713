import React, { useEffect, useState } from 'react';

const ProgressBar = ({page}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(page * 33.33)
  }, [page])


  return (
      <div className="progress-bar space">
        <div
          className="progress-bar-fill"
          style={{ width: `${progress}%` }}
        />
      </div>
  );
};

export default ProgressBar;