import React, { useEffect, useState } from 'react';
import Phase from './Phase';
import { CircularProgressbar } from 'react-circular-progressbar';
import ProgressBar from './ProgressBar';
import 'react-circular-progressbar/dist/styles.css';
import './App.css'


const Survey = () => {
  const [answers, setAnswers] = useState([]);
  const [score, setScore] = useState('');
  const [page, setPage] = useState(1)
  const [errors, setErrors] = useState([])


  const questions = [
    {
      question: 'How much do you weigh (lb)?',
      type: 'numberInput',
      category: 'personal',
      name: 'weight',
      optional: false
    },
    {
      question: 'What sport or activity were you doing when you tore your ACL?',
      type: 'dropdown',
      options: ['Football', 'Lacrosse', 'Soccer', 'Basketball', 'Wrestling', 'Volleyball', 'Taekwondo', 'Jiu-jitsu', 'Water Skiing', 'Skiing', 'Snowboarding', 'Tennis', 'other'],
      ignore: true,
      category: 'personal',
      name: 'sport',
      optional: false
    },
    {
      question: 'When was your surgery?',
      type: 'date', // TODO
      category: 'surgery',
      name: 'date',
      optional: false
    },
    {
      question: 'What kind of graft was used?',
      type: 'dropdown',
      options: ['Patellar Tendon', 'Quadriceps', 'Hamstring', 'Achilles', 'Cadaver ACL'],
      category: 'surgery',
      ignore: true,
      name: 'graft',
      optional: false
    },
    {
      question: 'Can you full extend your knee?',
      type: 'dropdown',
      options: ['yes', 'no'],
      category: 'mobility',
      name: 'extension',
      optional: false
    },
    {
      question: 'How much range of motion do you have when bringing your foot to your butt (flexing your knee)?',
      type: 'dropdown',
      options: ['full', 'almost there', 'somewhat passed 90 degrees', '90 degrees', 'less than 90'],
      values: [1, 0.75, 0.5, 0.25, 0],
      category: 'mobility',
      name: 'flexion', 
      optional: false
    },
    {
      question: 'What is your quadriceps limb symmetry index?',
      type: 'dropdown',
      options: ['greater than 90%', '80-90%', '70-80%', '60-70%', '50-60%', 'I don\'t know what this is/I have not tested'],
      values: [1, 0.75, 0.5, 0.25, 0, 0],
      category: 'strength',
      name: 'lsi',
      optional: false
    },
    {
      question: 'What is your maximum single leg leg press (lb) (leave blank if not sure)',
      type: 'numberInput',
      category: 'strength',
      name: 'leg press',
      optional: true
    },
    {
      question: 'What level of jumping are you comfortable with?',
      type: 'dropdown',
      options: ['none', 'double leg landing', 'double leg jumping', 'single leg landing', 'single leg jumping'],
      values: [0, 0.25,0.5,0.75,1],
      category: 'plyometrics',
      name: 'jumping',
      optional: false
    },
    {
      question: 'Have you passed your return to sport tests?',
      type: 'dropdown',
      options: ['yes', 'no', 'I don\'t know what those are'],
      values: [1,0,0],
      category: 'plyometrics',
      name: 'rts tests',
      optional: false
    },
    {
      question: 'Have you participated in lower intensity versions of your sport since surgery?',
      type: 'dropdown',
      options: ['yes', 'no'],
      category: 'sport',
      name: 'lower intensity sport',
      optional: false
    },
    {
      question: 'Are you confident that your knee will not give way while playing your sport?',
      type: 'dropdown',
      options: ['yes', 'no'],
      category: 'psychological',
      name: 'confidence',
      optional: false
    },
    {
      question: 'How do you feel about returning to sport?',
      type: 'dropdown',
      options: ['relaxed and confident', 'nervous and scared'],
      values: [1,0],
      category: 'psychological',
      name: 'feeling',
      optional: false
    },
    {
      question: "OPTIONAL: What's your email? Stay up to date about what we are building to help ACL patients and therapists.",
      type: 'text',
      ignore: true,
      category: 'psychological',
      name: 'email',
      optional: true
    },

  ];

  const pageQuestionMap = {
    1: [0,3],
    2: [4,10],
    3: [11,13]
  }

  const weights = {
    "psychological": 0.1,
    "mobility": 0.2,
    "strength": 0.25,
    "plyometrics": 0.25,
    "sport": 0.1,
    "surgery": 0.1
  }


  const changePage = (forward) =>{
    if (forward) {
      const currentErrors = validatePhase()
      setErrors(currentErrors)
      if (currentErrors.length == 0){
        setPage(page + 1)
        return true
      }
      return false
    } else {
      setPage(page - 1)
      return false
    }

  }

  const arrayRange = (start, stop, step) =>
    Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
    );


  const validatePhase = () => {
    const questionsRange = pageQuestionMap[page]
    const questionsFull = arrayRange(questionsRange[0], questionsRange[1], 1)
    const currentErrors = []
    questionsFull.map((question) => {
      if (!questions[question].optional && !answers[question]) {
        currentErrors.push(question)
      }
    })
    return currentErrors
  }


  const calculateReadiness = () => {
    if (changePage(true)){
    const weight = answers[0]
    const LPGoal = weight * 1.5
    const numbers = answers.map((answer, index) => {
      if(questions[index].ignore !== true) {
        if(questions[index].type === 'dropdown') {
          if(answer ===  'yes') {
            return 1 * weights[questions[index].category]
          } else if(answer === 'no') {
            return 0 * weights[questions[index].category]
          } else {
            const answerIdx = questions[index].options.findIndex((el) => el === answer)
            return questions[index].values[answerIdx] * weights[questions[index].category]
          }
        } else if (questions[index].type === 'numberInput') {
            if (index === 7) {
              if (answer == NaN || answer == undefined) {
                console.log("UNDEFINED")
                if (answers[8] == 'double leg landing'){
                  answer = weight * 0.75
                } else if (answers[8] == 'double leg jumping') {
                  answer = weight * 1
                } else if (answers[8] == 'single leg landing') {
                  answer = weight * 1.25
                } else if (answers[8] == 'single leg jumping') {
                  answer = weight * 1.5
                } else {
                  answer = weight * 0.5
                }
                console.log(answer)
              }
              const goal = answer/LPGoal  
              return (goal > 1 ? 1 : goal) * weights[questions[index].category]
            } else {
              return answer * weights[questions[index].category]
            }
        } else {
          const endDate = new Date()
          const startDate = new Date(answer)
          var monthsDiff = (endDate.getMonth() + (12 * (endDate.getFullYear() - startDate.getFullYear()))) - startDate.getMonth()
          if (startDate.getDate() > endDate.getDate()) {
            monthsDiff = monthsDiff - 1
          }
          return (monthsDiff > 9 ?  1  : 0) * weights[questions[index].category]
        }
      }
    })
    var catNums = numbers.map((n,i) => [n,questions[i].category]).filter(nc =>  nc[1] != 'personal' && nc[0] != undefined)
    catNums = catNums.reduce((a,c) => {
      if (a.length != 0 && a[a.length-1][0][1] === c[1]) {
        a[a.length-1].push(c)
      } else {
        a.push([c])
      }
      return a
    },[])
    console.log(catNums)
    catNums = catNums.map(c => {
      console.log(c)
      return c.reduce((acc, curr) => acc + curr[0],0)/c.length
    })

    const score = catNums.reduce((s,c) => s + c, 0)

    setScore(Math.round(score*100))
  }

  

  }

  useEffect(() => {
    submitValues()
  }, [score])

  const submitValues = () => {

    const scriptUrl = "https://script.google.com/macros/s/AKfycbxq2vdXkYRXL3ws8JdSmbna9lgtN_E6QP6aY9hnW1WXukrM0304cP7w-JWz3sMOZGew/exec"

    const formData = new FormData();
    answers.map((answer, index) => {
      if (answer){
        formData.append(questions[index].name, answer)
      } else {
        formData.append(questions[index].name, '')
      }
    })
    formData.append('score', score)

      fetch(scriptUrl, {method: 'POST', body: formData})
          .then(res => {
              console.log("SUCCESSFULLY SUBMITTED")
          })
          .catch(err => console.log(err))
  }

  const handleInputChange = (questionIndex, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[questionIndex] = value;
    setAnswers(updatedAnswers);
  };

  const renderScore = () => {
    if(score === '') {
      return (<></>)
    } else {
      const percentage = score;

      return (
       < CircularProgressbar 
       className={"score"} 
       value={percentage} 
       text={`${percentage}%`} 
       strokeWidth={15}/>
        
      )
    }
  }

  const restart = () =>{
    setAnswers([])
    setPage(1)
  }

  const scrollToWhy = () => {
    const targetDiv = document.getElementById('why');
    targetDiv.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    
    <div className="container">
      <div className="form">
        <div className="topLine">Are you ready?</div>
        <div id="butWhy" onClick={scrollToWhy}>Why?</div>
        <div className="mediumText centerText space">Find out in less than 2 minutes how fit you are to return to sport</div>

        {page <= 3 && <ProgressBar page={page}/>}
        {page <= 3 &&
        <Phase questions={questions} index={page}
        pageQuestionMap={pageQuestionMap}
        answers={answers}
        errors={errors}
         changePage={changePage} calculateReadiness={calculateReadiness}
         handleInputChange={handleInputChange} />
        }
        {page > 3 && 
        <div className="container">
          <div className="scoreInfo">
            {renderScore()}
            <div className="scoreMeaning"> 
              <h1 className="centerText"> What does it mean?</h1>
              <p>0-80% - Be patient, keep doing the work. You are on your way.</p>
              <p>80-90% - You are almost there! Don't cheat yourself now though, shore up your weaknesses and see this all the way through.</p>
              <p>> 90% - You've gone all the way, and you are ready to go back to your sport.</p>
            </div>
           </div>
          <button className="submit" onClick={restart}>Restart</button>
          <button id="downButton" className="arrow-button" onClick={scrollToWhy}>
            <span className="arrow-down"></span>
          </button>

        </div>
        } 
      </div>
      <div className="why" id="why">
        <div className="topLine">Why we built this</div>
        <WhyBlock />
        <Papers />
      </div>
    </div>
  );
};

function WhyBlock() {
  return (
    <div className="textContainer" id="whyBlock">
      <div className="mediumText space">1 in 3 people who return to intense cutting sports sustain a reinjury in the first 2 years after ACL reconstruction.<br/>For every month that return to sport is delayed until 9 months, the rate of knee reinjury is reduced by 51%.
</div>

      <div className="mediumText space bold">We built this tool to raise awareness about what's at stake when returning to sport after ACL reconstruction. </div>

      <div className="mediumText space">Patients either return too fast or with too little preparation, without knowing that they are unprepared. This is often followed by complications down the road, in both the short and long term.</div>

      <div className="mediumText space">It's not you, it's the system. Insurance-backed therapists are frequently not equipped to follow your whole journey from off the surgical table back to your sport. But it's your body, so you need to know what it takes to be ready.</div>

      <div className="mediumText space">The readiness score uses science-backed standards to evaluate your ability to return to sport. It's a small step in a larger initiative to help physical therapists ensure their athletes know exactly how to get back to sport without any of the uncertainty that currently exists in the process.</div>

      <div className="mediumText">Questions about the tool? Want to chat with former ACL patients who built this? Drop us a line at <label className="bold">aclreadiness @ gmail . com</label>.</div>
    </div>
  )
}

function Papers() {
  return (
    <div className="textContainer smallText" id="citations">
      <div className="citation">Buckthorpe M, Della Villa F. Recommendations for Plyometric Training after ACL Reconstruction – A Clinical Commentary. IJSPT. 2021;16(3):879-895. doi:10.26603/001c.23549</div>
      <div className="citation">Buckthorpe M, Tamisari A, Villa FD. A TEN TASK-BASED PROGRESSION IN REHABILITATION AFTER ACL RECONSTRUCTION: FROM POST-SURGERY TO RETURN TO PLAY - A CLINICAL COMMENTARY. Int J Sports Phys Ther. 2020 Aug;15(4):611-623. PMID: 33354394; PMCID: PMC7735691.</div>
      <div className="citation">Grindem H, Snyder-Mackler L, Moksnes H, et al Simple decision rules can reduce reinjury risk by 84% after ACL reconstruction: the Delaware-Oslo ACL cohort study British Journal of Sports Medicine 2016;50:804-808.</div>
      <div className="citation">Webster KE, Feller JA. Development and Validation of a Short Version of the Anterior Cruciate Ligament Return to Sport After Injury (ACL-RSI) Scale. Orthop J Sports Med. 2018 Apr 4;6(4):2325967118763763. doi: 10.1177/2325967118763763. PMID: 29662909; PMCID: PMC5894922.</div>
    </div>
  )
}


export default Survey;
