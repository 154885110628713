import React, { useState } from 'react';
import './App.css'
import { Formik, Form, Field, ErrorMessage } from 'formik';


const Phase = ({questions, answers, errors, pageQuestionMap, index, changePage, calculateReadiness, handleInputChange}) => {

    const renderQuestions = () => {
        

        return questions.map((question, i) => {
            if (i >= pageQuestionMap[index][0] && i <= pageQuestionMap[index][1]) {
          return (
            <div className={"question"} key={i}>
              <label className="questionLabel">{question.question}</label>
              <div className="field"> 
              {errors.includes(i) && <div className="formError">Required Field</div>}
              {question.type === 'dropdown' && (
                <select className="answerDropdown response" value={answers[i] || ''} onChange={(e) => handleInputChange(i, e.target.value)}>
                  <option value="">Select an option</option>
                  {question.options.map((option, optionIndex) => (
                    <option  key={optionIndex} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              )}
              {question.type === 'numberInput' && (
                <input
                  className="answer response"
                  type="number"
                  value={answers[i] || ''}
                  onChange={(e) => handleInputChange(i, e.target.value)}
                />
              )}
              {question.type === 'date' && (
                <input
                  className="answer response"
                  type="date"
                  value={answers[i] || ''}
                  onChange={(e) => handleInputChange(i, e.target.value)}
                />
              )}
              {question.type === 'text' && (
                <input
                  className="answer response"
                  type="email"
                  value={answers[i] || ''}
                  onChange={(e) => handleInputChange(i, e.target.value)}
                />
              )}
              
              </div>
            </div>
          )}
        });
      };

    return (
        <div className="container">
            <div className="space">
            {renderQuestions()}
            </div>
            <div className="buttons">
            {index > 1 && 
            <button className="submit" onClick={() => {changePage(false)}}>Back</button>
            }
            {index < 3 ?
            <button className="submit" onClick={() => {changePage(true)}}>Next</button> :
            <button className="submit" onClick={calculateReadiness}>Show Me</button>
            }
            </div>
        </div>
    )
}

export default Phase;